.body-bg-full .page-container.bg-phishstrike-black{
    background-color: #1A1A1A;
}

.btn-phishstrike-red {
    color: #fff;
    background-color: #e2003e;
    border-color: #e2003e;
}

.btn-phishstrike-red:hover {
    color: #fff;
    background-color: #d9534f;
    border-color: #d9534f;
}

.body-bg-full .container .logo {
    margin-bottom: 15px;
}

.body-bg-full .page-container {
    background-color: rgba(90,60,40,.4);
}

a {
    color: #fff;
}

a:focus, a:hover {
	color: #9a9a9a;
}

.new-input-white {
	width: 100%;
    border: none;
    padding: 10px 20px;
    outline: none;
    text-align: center;
}

.text-muted {
    color: white;
}
.checkbox-custom input[type=checkbox]+label.checkbox-muted:before {
    border-color: white;
}
